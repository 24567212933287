import React from 'react';


class TaskElement extends React.Component {

    constructor(props) {
        super(props)
        this.checkItem = this.checkItem.bind(this)
        this.state = {
            parent: props.parent,

            item: props.item,
            checked: props.checked,
            link: props.link,
            group: props.group,
            price: props.price

        }
        this._linkURL = new URL(props.link)
        this.state.hostname = this._linkURL.hostname
    }


    async checkItem() {

        this.checkboxState = document.getElementById("checkbox"+encodeURIComponent(this.state.group)+encodeURIComponent(this.state.item)).checked
        //alert(this.checkboxState)
        //alert(this.checkboxState)
        this.newState = this.checkboxState
        //alert(this.newState)
        await this.state.parent.callback({
            "group": this.state.group,
            "name": this.state.item,
            "checked": this.newState
        }).catch((err) => alert(err.stack))

    }

    render() {
        if (!this.state.checked) {
            return (
                <div className={"block flex items-center p-2 border-b border-b-gray-400"}>
                    <div>

                        <input type={"checkbox"} onChange={this.checkItem}
                                 id={"checkbox" + encodeURIComponent(this.state.group) + encodeURIComponent(this.state.item)}
                                 className={"min-w-10 min-h-10  inline-block  text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"}/>

                    </div>


                    <p className={"ml-3 p-1 font-mono shrink drop-shadow-md object-scale-down"}><a href={this.state.link}>${this.state.price}</a></p>


                    <a href={this.state.link} className={"ml-3 inline-block object-scale-down"} target={"_blank"}>

                            <div>

                                <p className={"inline-block text-sm sm:text-sm md:text-sm lg:text-md shrink object-scale-down text-clip"}>
                                    {this.state.item}
                                </p>
                                <p className={"inline-block italic text-xs text-gray-400 ml-5 object-scale-down text-clip"}>
                                    on {this.state.hostname}
                                </p>
                            </div>

                    </a>
                </div>
            )
        } else {






            return (
                <div className={"block flex items-center p-2 border-b border-b-gray-400"}>
                    <div>

                        <input type={"checkbox"} onChange={this.checkItem}
                               id={"checkbox" + encodeURIComponent(this.state.group) + encodeURIComponent(this.state.item)}
                               className={"min-w-10 min-h-10  inline-block  text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-red-800"}
                               checked/>
                    </div>


                    <a href={this.state.link} className={"ml-5 inline-block "} target={"_blank"}>

                            <div>
                                <p className={"text-sm sm:text-sm md:text-sm lg:text-md text-clip inline-block line-through decoration-red-500 decoration-2 shrink "}>
                                    {this.state.item}
                                </p>
                                
                            </div>

                    </a>
                </div>
            )
        }
    }



}

export default TaskElement
