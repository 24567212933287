import icon from './logo192.png'
import './App.css';
import posthog from 'posthog-js'

import React from 'react';
import TaskElement from "./components/TaskElement";
import TaskGroup from "./components/TaskGroup";
import {nanoid} from "nanoid";

class App extends React.Component {

  delay = (ms) => {return new Promise(res => setTimeout(res, ms))};

  ws = null

  constructor(props) {
    super(props)
    this.generateListElements = this.generateListElements.bind(this)
    this.onClientChange = this.onClientChange.bind(this)
    this.onServerChange = this.onServerChange.bind(this)
    this.close = this.close.bind(this)
    this.onInactivity = this.onInactivity.bind(this)
    this.state = {
      ws: null,
      db: null,
      loading: true
    }

    this.initializeWS()


  }

  initializeWS() {

    this.ws = new WebSocket("wss://api.techman.dev/tasks2/");
    posthog.capture('Websocket Init', {  })
    //alert("Init")
    //this.timeout = this.delay(5000).then(this.onInactivity)

    this.ws.addEventListener('open', async () => {
      posthog.capture('Websocket Opened', {  })
      this.ws.send(JSON.stringify({
        method: "get",
        taskList: "xmas"
      }))
    })

    this.ws.addEventListener("message", async event => {
      posthog.capture('Websocket Message Received', { message: event.data })
      this.onServerChange(event.data)
    })

  }

  async onClientChange(item) {
    //alert(JSON.stringify(item))
    posthog.capture('Checkbox clicked', { message: JSON.stringify(item) })
    await this.setState({loading: true})
    try {
      await this.ws.send(JSON.stringify({
        method: "checkItem",
        item: item,
        taskList: "xmas"
      }))
    } catch (err) {
      console.log(err.stack)
      await this.ws.close()
      await this.initializeWS()
      await this.ws.send(JSON.stringify({
        method: "checkItem",
        item: item,
        taskList: "xmas"
      }))
    }

  }

  async onServerChange(data) {

    data = JSON.parse(data)
    console.log(data)
    //alert(JSON.stringify(data))
    if (data.method === "update") {
      //alert(JSON.stringify(data))
      this.setState({
        loading: false,
        db: data.data
      })
      //alert("List Refreshed!")
      //alert(this.state.db)
    } else if (data.method === "get") {
      //alert(JSON.stringify(data))
      this.setState({
        loading: false,
        db: data.data
      })
      //alert("List Refreshed!")
      //alert(this.state.db)
    }

  }

  onInactivity() {
    alert("Inactive")
    //this.timeout = this.delay(5000)
    //clearTimeout(this._timeoutPromise)
    this._timeoutPromise=null
    this.timeout = null
  }

  generateListElements() {

    this.generationDB = []

    Object.keys(this.state.db.items).forEach((group) => {
      this._generationGroup = []

      Object.keys(this.state.db.items[group]).forEach((item) => {

        this._generateItem = this.state.db.items[group][item]
        this._generationGroup.push(<TaskElement
          item={item}
          checked={this._generateItem.checked}
          link={this._generateItem.link}
          parent={{callback: this.onClientChange}}
          group={group}
          price={this._generateItem.price}
          key={nanoid()}
        />)

      })

      this.generationDB.push(<TaskGroup
        items={this._generationGroup}
        name={group}
        key={nanoid()}
      />)
    })
  return this.generationDB
  }







  close() {
    this.ws.close()
    alert("Socket Closed")
  }



  render() {

    if (this.state.loading) {
      return (
          <div>
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <p id={"loading-message"} className={"text-lg text-gray-500"} style={{position: "fixed", bottom: 0}}>Not loading? Try refreshing the page</p>
          </div>
      )
    } else {








      return (
          <div className={"p-5 "}>
            <div className={"flex min-w-screen mb-10"}>
              <div className={"inline-block"}>
                <img src={icon} className={"shrink inline-block min-w-10 min-h-10 max-w-32 max-h-32 object-scale-down"}/>
                <div>
                  <h1 className={"text-3xl font-extrabold grow block text-red-700"}>2022 Xmas Wishlist</h1>
                  <p className={"text-xs block grow"}>by Jack Hubbard</p>
                </div>
              </div>


              <div className={"grow"}></div>


              <div className={"shrink relative bottom-2"}>
                <div className="pulsating-circle"></div>
              </div>
            </div>





            <div className={"mb-52"}>
              {this.generateListElements()}
            </div>







            <div className={"mt-25 p-10 w-full border-t-4 border-t-black"}>

              <div className={"border-2 border-green-600 mb-10 rounded-md p-5"}>

                <h2 className={"text-xl text-red-700 mb-2"}>How does this work?</h2>

                <h4 className={"text-lg"}>Two main features...</h4>

                <div className={""}>
                  <div className={"flex flex-row align-middle mt-5 mb-2"}>
                    <p className={"p-2 text-xs"}>●</p>
                    <p>You can click on any item to see it on a retailer's site (i.e. Amazon)</p>
                  </div>
                  <div className={"flex flex-row align-middle mb-5"}>
                    <p className={"p-2 text-xs"}>●</p>
                    <p>If you check off items, your change will be saved automatically, and it will update on everyone else's device</p>
                  </div>
                </div>
              </div>


              <div className={"border-2 border-green-600 rounded-md p-5"}>
                <h2 className={"text-xl text-red-700 mb-2"}>Questions? Comments? Concerns?</h2>
                <p>Please shoot me a text or email me at <a href={"mailto:jack@techman.dev"} className={"text-blue-600"}>jack@techman.dev</a></p>
              </div>
            </div>




          </div>
      )
    }
  }



}

export default App;
