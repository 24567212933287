import React from 'react';

class TaskGroup extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            name: props.name,
            items: props.items
        }
    }


    render() {
        return (
            <div className={"block m-3 border-2 border-green-600 p-5 rounded-md shadow-xl mb-10"}>
                <h2 className={"text-xl p-3 border-b-2 border-b-orange-700 font-bold"}>
                    {this.state.name}
                </h2>
                <br/>
                {this.state.items}
                <br/>
            </div>
        )
    }



}

export default TaskGroup
